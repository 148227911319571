import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { completeReportCSV, CustomReport } from "../../api/server";
import PaginatedItems from "./pagination";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import moment from "moment";

let columnOptions = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Address", value: "addr1" },
  { name: "SSN", value: "ssn" },
  { name: "Attorney Name", value: "attorneyname" },
  { name: "Attorney Phone", value: "attyphone" },
  { name: "Attorney Email", value: "attyemail" },
  { name: "Course", value: "course" },
  { name: "Attorney Code", value: "attorneycode" },
  { name: "CC Completion Date", value: "courseFirstDate" },
  { name: "DE Completion Date", value: "courseSecondDate" }
];

const validationSchema = Yup.object({
  columns: Yup.array().of(Yup.string()).min(1, "At least one column must be selected"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date must be greater than start date"),
  course: Yup.string().required("Course is required"),
});

const CompleteReport = () => {
  const [filterLoading, setFilterLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [cSVLoadingCertificate, setCSVLoadingCertificate] = useState(false);
  const [activeDate, setActiveDate] = useState();
  const [reportData, setReportData] = useState()
  const [total, setTotal] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [payload, setPayload] = useState(1);
  const [certificateFilter, setCertificateFilter] = useState("3Month");
  const [courceOption, setCourceOption] = useState("CC&DE")
  const {
    handleChange,
    touched,
    values,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      course: "CC&DE",
      columns: columnOptions.map(option => option.value),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("onsubmit>>>", values, cSVLoadingCertificate);

      if (cSVLoadingCertificate) {
        donwloadCSV(values)
      }
      else {
        fetchData(values, certificateFilter);

      }
    },
  });

  const donwloadCSV = async (values) => {
    if (certificateFilter == "Date") {
      values = { ...values, range: certificateFilter, course: courceOption }
    }
    else {
      values = { ...values, range: certificateFilter, startDate: "", endDate: "", course: courceOption }
    }
    console.log("valuesvalues>>>>", values)
    // setLoading(true);
    values.columns = values.columns.filter(
      item => item !== "courseFirstDate" && item !== "courseSecondDate"
    );
    setCsvLoading(true)
    try {
      const res = await completeReportCSV(values);
      //   setLoading(false);
      console.log("resi>>>>>", res)
      const linkHandler = document.getElementById("csvhandler");
      if (linkHandler) {
        linkHandler.setAttribute("href", res.data.csvlink);
        linkHandler.click();
      }
      setCsvLoading(false)

    } catch (err) {
      //   toast.error(err.response.data.error);
    } finally {
      //   setLoading(false);
      setCsvLoading(false)

    }
  };
  const handleErrors = async (setError) => {
    console.log("setErrorssetErrorssetErrors")
    if (setError) {
      setTimeout(() => {
        setError({ startDate: "Start date is required", endDate: "End date is requireds" })
      }, 3);
    }

  }

  const fetchData = async (values, value, seterror, cource) => {
    console.log("values.cccc", values)
    if (values.columns.length == 0) {
      setTimeout(() => {
        seterror({ columns: "At least one column must be selected" })
      }, 0);
      return
    }
    if (value == "Date") {
      values = { ...values, range: value, course: cource ? cource : courceOption }
    }
    else {
      values = { ...values, range: value, startDate: "", endDate: "", course: cource ? cource : courceOption }
    }
    values.columns = values.columns.filter(
      item => item !== "courseFirstDate" && item !== "courseSecondDate"
    );
    console.log("valuessssssss", values)
    values.columns.filter((item) => item !== "courseSecondDate")
    try {
      setFilterLoading(true);
      const res = await CustomReport(pageNo, values);
      console.log("CustomReportCustomReportCustomReportCustomReport", res)
      setReportData(res?.data?.data)
      setTotal(res?.data?.data?.total)
      setFilterLoading(false);
      setPayload(values)
      // Set state with new data

    } catch (err) {
      //   toast.error(err.response.data.error);
    } finally {
      setFilterLoading(false);
    }
  };

  const handleColumnChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setFieldValue("columns", [...values.columns, value]);
    } else {
      setFieldValue("columns", values.columns.filter((item) => item !== value));
    }
  };
  useEffect(() => {
    if (courceOption) {

      columnOptions = columnOptions.filter(
        option => option.value !== "courseFirstDate" && option.value !== "courseSecondDate"
      );


      if (courceOption === "CC" || courceOption === "CC!DE") {
        columnOptions.push({ name: "CC Completion Date", value: "courseFirstDate" });
      } else if (courceOption === "DE" || courceOption === "DE!CC") {
        columnOptions.push({ name: "DE Completion Date", value: "courseSecondDate" });
      } else {

        columnOptions.push(
          { name: "CC Completion Date", value: "courseFirstDate" },
          { name: "DE Completion Date", value: "courseSecondDate" }
        );
      }
    }
  }, [courceOption])
  useEffect(() => {
    if (payload.range) {
      fetchData(payload)

    }
    else {
      const values = { course: "CC&DE", columns: ["attyemail", "name", "email", "phone", "addr1", "ssn", "attorneyname", "attyphone", "course", "attorneycode", "courseFirstDate", "courseSecondDate"] }
      fetchData(values, "3Month")
    }
  }, [pageNo])

  return (
    <>
      <a id="csvhandler" target="_blank"></a>
      <div>
        <div className="wrapper">
          <DashBoardSidebar />
          <div id="content">
            <DashboardNavbar />
            <section className="manage-students language_wrap">
              <div className="col-lg-12 mt-4">
                {
                  certificateFilter === "Date" &&
                  <form className="row align-items-center mt-5" onSubmit={handleSubmit}>
                    <h6 className="fw-bold text-center col-2 mb-4">
                      <span className="text_success">Date Range:</span>
                    </h6>
                    <div className="col-3">
                      <div className="input_wrap" style={{ marginBottom: "0px" }}>
                        <label className="label-title">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          style={{ height: "50px" }}
                          placeholder="Enter start date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="startDate"
                          value={values.startDate}
                        />
                        <p className="text-danger mb-0" style={{ minHeight: "25px" }}>
                          {errors.startDate && touched.startDate ? errors.startDate : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-center">
                      <div className="form-group">
                        <label className="label-title">To</label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="input_wrap" style={{ marginBottom: "0px" }}>
                        <label className="label-title">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          style={{ height: "50px" }}
                          placeholder="Enter end date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="endDate"
                          value={values.endDate}
                        />
                        <p className="text-danger mb-0" style={{ minHeight: "25px" }}>
                          {errors.endDate && touched.endDate ? errors.endDate : ""}
                        </p>
                      </div>
                    </div>
                    <div className="text-center col-2 mb-4">
                      <button
                        className="btn_brnd"
                        disabled={activeDate === "Certificate" && filterLoading}
                        type="submit"
                        onClick={() => {
                          setActiveDate("Certificate");
                        }}
                      >
                        {activeDate === "Certificate" && filterLoading ? (
                          <ThreeDots
                            height="20"
                            width="60"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ display: "block" }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                }

              </div>

              <div className="card" style={{ height: "auto" }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3
                      style={{
                        fontWeight: "bold",
                        fontSize: "22px",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      Completion Report
                    </h3>
                    <div className="d-flex justify-content-between align-items-center dasboard ">
                      <button
                        className="btn_brnd"
                        style={{ padding: "6px 30px", fontWeight: "500" }}
                        type="button"
                        disabled={csvLoading}
                        onClick={() => {
                          donwloadCSV(values)
                        }}
                      >
                        {csvLoading ? (
                          <ThreeDots
                            height="20"
                            width="94"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ display: "block" }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Export Data"
                        )}
                      </button>
                      <select

                        className="form-select ms-4"
                        style={{ width: "150px" }}
                        aria-label="Default select example"
                        name="range"
                        value={certificateFilter}
                        onChange={(e) => {
                          setCertificateFilter(e.target.value);

                          if (e.target.value !== "Date")
                            fetchData(values, e.target.value, setErrors);
                          // setPageNo(1);
                        }}
                      >

                        <option value="Today">Today</option>
                        <option value="Week">Week</option>
                        <option value="Month">
                          Month
                        </option>
                        <option selected value="3Month">
                          3 Month
                        </option>
                        <option value="Date">Custom Range</option>
                      </select>
                      <select
                        className="form-select ms-4"
                        style={{ width: "150px" }}
                        aria-label="Default select example"
                        onChange={(e) => {
                          setCourceOption(e.target.value)
                          console.log("certificateFilvalues.startDate && !values.endDate", certificateFilter == "Date" && !values.startDate && !values.endDate)


                          if (certificateFilter == "Date" && !values.startDate && !values.endDate) {
                            handleErrors(setErrors)

                          }
                          else {
                            fetchData(values, certificateFilter, setErrors, e.target.value);
                          }

                        }

                        }
                        onBlur={handleBlur}
                        name="course"
                        value={courceOption}
                      >
                        <option value="CC&DE" selected>
                          CC & DE
                        </option>
                        <option value="CC">CC</option>
                        <option value="DE">DE</option>
                        <option value="CC!DE">CC But Not DE</option>
                        <option value="DE!CC">DE But Not CC</option>
                      </select>
                    </div>
                  </div>
                  {
                    errors.columns && <p className="text-danger mb-0" style={{ minHeight: "25px" }}>
                      {errors.columns && touched.columns ? errors.columns : ""}
                    </p>}
                  <div className=" my-4 " style={{ display: 'flex', border: 'none', columnGap: '1.5rem', rowGap: '18px', flexWrap: 'wrap' }}>

                    {columnOptions.map((col) => (
                      <div className=" iagree_radio" key={col.value}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={col.value}
                          value={col.value}
                          checked={values.columns.includes(col.value)}
                          onChange={handleColumnChange}
                        />
                        <label className="form-check-label" htmlFor={col.value}>
                          {col.name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div style={{ overflowX: 'auto', maxWidth: '100%' }}>

                    <table className="table table-borderless table-responsive" >
                      <thead>
                        <tr>
                          {values.columns.map((col) => {
                            const column = columnOptions.find((option) => option.value === col);
                            return (
                              <th scope="col" key={col}>
                                {column && column.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {filterLoading ? (
                          <tr>
                            <td colSpan={4}>
                              <ThreeDots
                                height="40"
                                width="80"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            </td>
                          </tr>
                        ) : reportData && reportData?.result?.length > 0 ? (
                          reportData.result.map((item, index) => (
                            <tr key={index}>
                              {
                                console.log("itemitemitem", item)
                              }
                              {values.columns.map((col) => (
                                <td key={col}>
                                  {
                                    console.log("colcolcol", col)
                                  }
                                  {/* {col == "courseFirstDate" && item[col] ? "date": ""   : item[col] || 'N/A'} */}
                                  {col == "courseFirstDate" || col == "courseSecondDate" ? item[col] && moment(item[col]).format("MMMM Do YYYY") || "" : item[col] || 'N/A'}
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (

                          <td
                            colSpan={3}
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            No Data Found
                          </td>
                        )}
                      </tbody>

                    </table>
                  </div>
                  {!filterLoading && total !== 0 && total > 10 && (
                    <PaginatedItems
                      total={total}
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                      pageSize={10}
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </>
  );
};

export default CompleteReport;