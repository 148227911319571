import React from "react";
import logo from "../assets/images/logo.png";
import navbar from "../assets/images/SiteImg/navbar.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../store/userSlice";

const Header = ({ handleAttorneyClick = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div>
      {isLoggedIn && user ? (
        <>
          <header className="header header_wrap header_wrap_two">
            <div className="theme_container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to={"/"}>
                  {" "}
                  <img src={logo} alt="logo" />{" "}
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img src={navbar} alt="navbar" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0">
                    <div class="dropdown">
                      <Link
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className=" att-link nav-link"
                      >
                        <li className=" nav-item mr-80">
                          <Link></Link>
                          Attorneys
                        </li>
                      </Link>

                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            class="dropdown-item"
                            to={`/attorneys`}
                            onClick={handleAttorneyClick}
                          >
                            Why Evergreen?
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/setup-attorney-account"}
                          >
                            Set-up Attorney Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/locate-certificates"}
                          >
                            Locate Certificates
                          </Link>
                        </li>

                        <li>
                          <Link class="dropdown-item" to={"/login"}>
                            Attorney Dashboard
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            class="dropdown-item"
                            to={"/request-client-cards"}
                          >
                            Request Client Cards
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to={"/report-an-issue"}>
                            Report an Issue
                          </Link>
                        </li> */}
                      </ul>
                    </div>

                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80" to={`/about`}>
                        About Us
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to={`/faq`}>
                        FAQ
                      </Link>{" "}
                    </li>
                  </ul>

                  <div class="dropdown">
                    <a
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      class=" dropdown-toggle"
                    >
                      {localStorage.getItem("isJoint") === "true"
                        ? user?.user?.data?.joint_firstname === "" &&
                          user?.user?.data?.joint_lastname === ""
                          ? user?.user?.data?.firstname +
                            " " +
                            user?.user?.data?.lastname +
                            "'s Joint"
                          : user?.user?.data?.joint_firstname +
                            " " +
                            user?.user?.data?.joint_lastname +
                            " (Joint)"
                        : user?.user?.data?.firstname +
                          " " +
                          user?.user?.data?.lastname}
                    </a>

                    <ul class="dropdown-menu">
                      {user?.user?.data?.type === "User" && (
                        <>
                          <li>
                            <Link class="dropdown-item" to={`/userprofile`}>
                              Profile
                            </Link>
                          </li>
                          <Link to={"/dashboard"}>
                            <li>
                              <a class="dropdown-item">My Courses</a>
                            </li>
                          </Link>
                          <li>
                            <Link
                              class="dropdown-item"
                              to={"/request-language"}
                            >
                              Language Request
                            </Link>
                          </li>
                        </>
                      )}
                      {user?.user?.data?.type === "Atty" && (
                        <Link to={"/manage-clients"}>
                          <li>
                            <a class="dropdown-item">Dashboard</a>
                          </li>
                        </Link>
                      )}
                      {user?.user?.data?.type === "Admin" && (
                        <Link to={"/admin-dashboard"}>
                          <li>
                            <a class="dropdown-item">Dashboard</a>
                          </li>
                        </Link>
                      )}
                      {/* <li>
                        <a class="dropdown-item" href="#">
                          Accomplishment
                        </a>
                      </li> */}
                      <li>
                        <Link class="dropdown-item" to={"/contactUs"}>
                          Help Center
                        </Link>
                      </li>

                      <li>
                        <span
                          style={{ cursor: "pointer" }}
                          class="dropdown-item"
                          onClick={handleLogout}
                        >
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </header>
        </>
      ) : (
        // If not logged in, display login or register links

        <div>
          <header className="header header_wrap header_wrap_two">
            <div className="theme_container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to={"/"}>
                  {" "}
                  <img src={logo} alt="logo" />{" "}
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img src={navbar} alt="navbar" />{" "}
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0">
                    <div class="dropdown">
                      <Link
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className=" att-link nav-link"
                      >
                        <li className=" nav-item mr-80">
                          <Link></Link>
                          Attorneys
                        </li>
                      </Link>

                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            class="dropdown-item"
                            to={`/attorneys`}
                            onClick={handleAttorneyClick}
                          >
                            Why Evergreen?
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/setup-attorney-account"}
                          >
                            Set-up Attorney Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to={"/locate-certificates"}
                          >
                            Locate Certificates
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to={"/login"}>
                            Attorney Dashboard
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            class="dropdown-item"
                            to={"/request-client-cards"}
                          >
                            Request Client Cards
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to={"/report-an-issue"}>
                            Report an Issue
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80" to={`/about`}>
                        About Us
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link mr-80 " to={`/faq`}>
                        FAQ
                      </Link>
                    </li>
                  </ul>
                  <form className="d-flex">
                    <Link to={`/registration`}>
                      <button
                        className="btn btn-outline-light mr-22"
                        type="submit"
                      >
                        Sign Up
                      </button>
                    </Link>
                    <Link to={`/login`}>
                      <button className="btn btn-outline-light" type="submit">
                        Login
                      </button>
                    </Link>
                  </form>
                </div>
              </nav>
            </div>
          </header>
        </div>
      )}
    </div>
  );
};

export default Header;
