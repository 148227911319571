import React, { useState } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { sendIssueReport } from "../../api/server";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const ReportAnIssue = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    await sendIssueReport(values)
      .then((res) => {
        toast.success("Issue submitted successfully");
        resetForm();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="my-profile">
          <h1 id="firstcourse">
            Report an <span className="text_success">Issue</span>
          </h1>
          <div className="head mt-60">
            <h1 className="f18">Denotes a required field</h1>
          </div>
        </div>

        <div className="row mt-40">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className="row mt-40">
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Name</label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Email</label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Enter your Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Subject</label>
                      <Field
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                      />
                      <ErrorMessage
                        name="subject"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="input_wrap mt-40">
                      <label className="label-title">Message</label>
                      <Field
                        as="textarea"
                        name="message"
                        className="form-control"
                        placeholder="Enter your message"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    disabled={loading}
                    className="btn_brnd"
                    type="submit"
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        toast.error("Form validation failed");
                      }
                    }}
                  >
                    {loading ? (
                      <ThreeDots
                        height="20"
                        width="60"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReportAnIssue;
