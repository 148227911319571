import axios from "axios";

// Create a function that returns the Axios instance with dynamic headers
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
const updateInstance = (token) => {
  // Interceptor to update Authorization header whenever the token changes
  if (token) {
    instance.defaults.headers[
      "Authorization"
    ] = `Bearer ${token}`;
  }
  console.log("token>>>>>>>>>>>>",token)
  // instance.interceptors.request.use((config) => {
   
  //   console.log("configconfigconfigconfigconfigconfig",config)
  //   return config;
  // });

  return instance;
};

export default updateInstance;

export const CustomReport = async (pageNo, values) => {
  return await instance.post(
    `admin/completeReport/${pageNo}`,
    values
  );
};
export const completeReportCSV = async (values) => {
  return await instance.post(`admin/completeReportCSV`, values);
};


// Signup
export const userSignup = async (body) => {
  return await instance.post(`user/register`, body);
};

// Login
export const userLogin = async (body) => {
  return await instance.post(`user/login`, body);
};

// forgot password
export const forgotPassword = async (body) => {
  return await instance.post(`user/reset-password`, body);
};

// reset password
export const resetPassword = async (body) => {
  return await instance.post(`user/forgot-password`, body);
};

// get the user data for edit
export const userEdit = async (id) => {
  return await instance.get(`user/get_user_id/${id}`);
};

// update the user data for edit
export const userUpdate = async (values, id) => {
  return await instance.post(`user/update-user/${id}`, values);
};

// get the state data in dropdown
export const getStates = async () => {
  return await instance.get(`user/get_state`);
};

export const addLanguage = async (values) => {
  return await instance.post(`user/add_language`, values);
};
//
export const VerifyUser = async (body) => {
  return await instance.post(`attorney/check_attorney_code`, body);
};

// get the county data in dropdown
export const getCounty = async (body) => {
  return await instance.post(`user/get_state_county`, body);
};

// get the district data in dropdown
export const getDistrict = async (body) => {
  return await instance.post(`user/get_state_district`, body);
};

// enroll the 1st Course
export const enrollCourse = async (type, id, values) => {
  return await instance.post(`${type}/enroll_first/${id}`, values);
};

// enroll the 2st Course
export const enroll2ndCourse = async (type, id, values) => {
  return await instance.post(`${type}/enroll_second/${id} `, values);
};

export const updateBankruptcy = async (type, id, values) => {
  return await instance.post(`${type}/case_number/${id} `, values);
};

export const couponCodeCalculator = async (type, values) => {
  return await instance.post(`${type}/coupon_code_calculator`, values);
};

export const updateCourseInvoice = async (type, values) => {
  return await instance.post(`${type}/stripe_payment`, values);
};

// render dashBoard according to the course status
export const getDashboard = async (type, id, query = "false") => {
  return await instance.get(`${type}/userDashboard/${id}?isJoint=${query}`);
};

// render 1st course
export const courseData = async (type, id, query = "false") => {
  return await instance.post(
    `${type}/course_first_data/${id}?isJoint=${query}`
  );
};

// how'd this happen 1st course
export const howDidHappen = async (type, values) => {
  return await instance.post(`${type}/how_did_happen`, values);
};

// evaluate 1st course
export const evaluateData = async (id, values, query = "false") => {
  return await instance.post(`user/evaluation/${id}?isJoint=${query}`, values);
};

// update 1st course
export const courseUpdate = async (type, id, values, query = "false") => {
  return await instance.post(
    `${type}/course_first_updation/${id}?isJoint=${query}`,
    values
  );
};

// update 1st course
export const setSecurityQuestion = async (id, values) => {
  return await instance.post(`user/add_security_question/${id}`, values);
};

// render 2nd course
export const courseData2 = async (type, id, query = "false") => {
  return await instance.post(
    `${type}/course_second_data/${id}?isJoint=${query}`
  );
};

// update 2nd course
export const courseUpdate2 = async (type, id, values, query = "false") => {
  return await instance.post(
    `${type}/course_second_updation/${id}?isJoint=${query}`,
    values
  );
};

// Register User with attorney code
export const fetchAttorney = async (values) => {
  return await instance.post(`attorney/fetch_attorney`, values);
};

//edit attorney
export const downloadBudget = async (id, values) => {
  return await instance.post(`user/download-budget/${id}`, values);
};

// Submit Budget analysis
export const budgetAnalysis = async (values, type, userId) => {
  return await instance.post(`${type}/budget_analysis${"/" + userId}`, values);
};

// Submit Budget analysis
export const getBudgetAnalysis = async (id, type) => {
  return await instance.get(`${type}/get_budget_analysis/${id}`);
};

// get Budget analysis
export const getBudgetAnalysisSecond = async (id, type) => {
  return await instance.get(`${type}/get_budgetAnalysis_second/${id}`);
};
// Submit Budget analysis
export const postBudgetAnalysisSecond = async (id, type, values) => {
  return await instance.post(`${type}/budgetAnalysis_second/${id}`, values);
};

// Submit Budget analysis
export const BudgetAnalysisSecond = async (values, type, userId) => {
  return await instance.post(`${type}/networth_second${"/" + userId}`, values);
};

// Submit Second Review Questions
export const quizSecond = async (values, type, userId, query = "false") => {
  return await instance.post(
    `${type}/quiz_second${"/" + userId}?isJoint=${query}`,
    values
  );
};

// Submit Budget analysis
export const changePassword = async (values) => {
  return await instance.post(`user/change-password`, values);
};
export const getBudgetanalysisData = async (id) => {
  return await instance.get(`user/get_budget_analysis_summary/${id}`);
};

// send contact
export const sendContactQuery = async (values) => {
  return await instance.post(`attorney/contact_us`, values);
};

// // US country States api
// export const getStates=()=>{
//     return instance.get(`states`)
// }

// ADMIN ENDPOINTS

// get all students
export const getAdminDashboard = async () => {
  return await instance.get(`admin/admin_dashboard`);
};

export const getAdminDashboardCardDats = async (
  url,
  pageNo,
  statCourse,
  restQuery = ""
) => {
  return await instance.get(
    `admin/${url}/${pageNo}?course=${statCourse}${restQuery}`
  );
};

export const getAdminDashboardCertData = async (
  url,
  statCourse,
  restQuery = ""
) => {
  return await instance.get(`admin/${url}?course=${statCourse}${restQuery}`);
};

export const getCSVink = async (values) => {
  return await instance.post(`admin/make_csv`, values);
};

// get all students
export const getAllStudents = async (pageNo) => {
  return await instance.get(`admin/students_course_detail/${pageNo}`);
};

// search student
export const searchStudents = async (pageNo, searchKey) => {
  return await instance.post(
    `admin/search_students_course_detail/${pageNo}`,
    searchKey
  );
};

// delete student
export const deleteStudent = async (id, pageNo) => {
  return await instance.delete(`admin/delete_user/${id}/${pageNo}`);
};

// delete attorney
export const deleteAttorney = async (id, pageNo) => {
  return await instance.delete(`admin/delete-attorney/${id}/${pageNo}`);
};

export const deleteJointInfo = async (id) => {
  return await instance.delete(`admin/delete_joint/${id}`);
};

// get the user data for edit
export const getUserData = async (id, query = "?type=User") => {
  return await instance.get(`admin/get_user_detail/${id}${query}`);
};

// get the budget analysis summary of specific user
export const getBudgetanalysisDataAdmin = async (id) => {
  return await instance.get(`admin/get_budget_analysis_summary/${id}`);
};

// get the course progress of specific user
export const getCourseProgress = async (id, course) => {
  return await instance.post(`admin/fetch_student_course_detail/${id}`, course);
};

// update the course progress of specific user
export const updateCourseProgress = async (id, data) => {
  return await instance.post(`admin/update_user_course_detail/${id}`, data);
};

// update profile
export const updateProfile = async (id, values) => {
  return await instance.post(`admin/update_user/${id}`, values);
};

// update profile
export const updateProfileByUser = async (id, values) => {
  return await instance.post(`user/update_user/${id}`, values);
};

// get all attormney
export const getAllAttorney = async (pageNo) => {
  return await instance.get(`admin/fetch_attorney/${pageNo}`);
};

// search attorney
export const searchAttorney = async (pageNo, searchKey) => {
  return await instance.post(`admin/search_attorney/${pageNo}`, searchKey);
};

// get all clients
export const getAttorneyClients = async (pageNo, attorney) => {
  return await instance.post(`admin/fetch_student_detail/${pageNo}`, attorney);
};

// search clients
export const searchAttorneyClients = async (pageNo, values) => {
  return await instance.post(
    `admin/fetch_search_student_detail/${pageNo}`,
    values
  );
};

//edit attorney
export const editAttorney = async (id, values) => {
  return await instance.post(`admin/edit_account/${id}`, values);
};

// languages
export const getLanguages = async (pageNo) => {
  return await instance.get(`admin/get_languages/${pageNo}`);
};

export const deleteLanguage = async (id, pageNo) => {
  return await instance.delete(`admin/delete_languages/${id}/${pageNo}`);
};

// attorney contact
export const getAttorneyContact = async (pageNo) => {
  return await instance.get(`attorney/all_attorney/${pageNo}`);
};
export const addAttorneyContact = async (values) => {
  return await instance.post(`attorney/add_attorney`, values);
};
export const deleteAttorneyContact = async (id, pageNo) => {
  return await instance.delete(`attorney/delete_attorney/${id}/${pageNo}`);
};

//Attorney code
export const getAttorneyData = async (pageNo) => {
  return await instance.get(`admin/fetch_attorney_code/${pageNo}`);
};
export const deleteAtterneyCode = async (id, pageNo) => {
  return await instance.delete(`admin/delete_attorney_code/${id}/${pageNo}`);
};
export const updateDiscount = async (id, discountValue) => {
  const value = { discount: discountValue };
  return await instance.post(`admin/update_attorney_code/${id}`, value);
};
// coupons
export const getCoupons = async (pageNo) => {
  return await instance.get(`admin/get_coupon/${pageNo}`);
};
export const addCoupons = async (values) => {
  return await instance.post(`admin/add_coupon`, values);
};
export const deleteCoupons = async (id, pageNo) => {
  return await instance.delete(`admin/delete_coupon/${id}/${pageNo}`);
};

// pending billing course first
export const getBillingFirst = async (pageNo) => {
  return await instance.get(`admin/new_pending_billing/${pageNo}?course=first`);
};
export const updateBillingFirst = async (values, pageNo) => {
  return await instance.post(
    `admin/update_pending_billing/${pageNo}?course=first`,
    values
  );
};

// pending billing course second
export const getBillingSecond = async (pageNo) => {
  return await instance.get(
    `admin/new_pending_billing/${pageNo}?course=second`
  );
};
export const updateBillingSecond = async (values, pageNo) => {
  return await instance.post(
    `admin/update_pending_billing/${pageNo}?course=second`,
    values
  );
};

// get evaluation first course
export const getEvaluationFirst = async () => {
  return await instance.get(`admin/evaluation_stat_first`);
};
export const searchEvaluationFirst = async (values) => {
  return await instance.post(`admin/evaluation_stat_first_search`, values);
};

// get evaluation second course
export const getEvaluationSecond = async () => {
  return await instance.get(`admin/evaluation_stat_second`);
};
export const searchEvaluationSecond = async (values) => {
  return await instance.post(`admin/evaluation_stat_second_search`, values);
};

// attorney invoice
export const getAttorneInvoice = async (pageNo, course) => {
  return await instance.post(`admin/attorney_invoice/${pageNo}`, course);
};
export const updateAttorneInvoice = async (pageNo, values) => {
  return await instance.post(`admin/update_invoice/${pageNo}`, values);
};

// card orders
export const getCardOrder = async (pageNo) => {
  return await instance.get(`admin/cards_request/${pageNo}`);
};
export const updateCardOrder = async (values, pageNo) => {
  return await instance.post(`admin/update_cards_request/${pageNo}`, values);
};

// booklets
export const getAllCCBooklet = async (pageNo) => {
  return await instance.get(`admin/get_booklet_first/${pageNo}`);
};
export const getAllDEBooklet = async (pageNo) => {
  return await instance.get(`admin/get_booklet_second/${pageNo}`);
};
export const updateBooklet = async (id, values) => {
  return await instance.post(`admin/upadte_booklet_record/${id}`, values);
};

// certificates
export const getAllCert = async (pageNo) => {
  return await instance.get(`admin/certificate_info/${pageNo}`);
};
export const generateCert = async (id, values, query = "false") => {
  return await instance.post(
    `admin/generate_certificate/${id}?isJoint=${query}`,
    values
  );
};
export const resendCert = async (values) => {
  return await instance.post(`admin/resend_certificate`, values);
};
export const generateCertSecond = async (id, values) => {
  return await instance.post(`admin/generate_certificate_second/${id}`, values);
};
export const uploadCert = async (id, values, query = "false") => {
  return await instance.post(
    `admin/manual_certificate/${id}?isJoint=${query}`,
    values
  );
};
export const uploadCertToCourt = async (id, values) => {
  return await instance.post(`admin/upload_court_cert/${id}`, values);
};
export const getFollowup = async (pageNo) => {
  return await instance.get(`admin/follow_up/${pageNo}`);
};
export const updateFollowUp = async (id, values) => {
  return await instance.post(`admin/follow_up/${id}`, values);
};

// ATTORNEY ENDPOINTS

// get all students
export const getAllClients = async (pageNo, attorney) => {
  return await instance.post(
    `attorney/fetch_student_detail/${pageNo}`,
    attorney
  );
};

// search student
export const searchClients = async (pageNo, values) => {
  return await instance.post(
    `attorney/fetch_search_student_detail/${pageNo}`,
    values
  );
};

export const locateCertificate = async (values) => {
  return await instance.post(`attorney/locate_certificate`, values);
};
export const attorneyCheck = async (values) => {
  return await instance.post(`attorney/attorney_check`, values);
};
// client Card request
export const sendClientCardRequest = async (values) => {
  return await instance.post(`attorney/clientCard`, values);
};

// report issue
export const sendIssueReport = async (values) => {
  return await instance.post(`attorney/report_issue`, values);
};

// report issue
export const makeSuggestion = async (values) => {
  return await instance.post(`attorney/send_suggestion`, values);
};

// report issue
export const leaveTestimonial = async (values) => {
  return await instance.post(`attorney/send_testimonial`, values);
};

// update attorney profile
export const updateAttorney = async (id, values) => {
  return await instance.post(`attorney/edit_account/${id}`, values);
};

//UnEnroll
export const UnEnroll = async (values) => {
  return await instance.post(`admin/unenrol_user`, values);
};