import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import ScrollToTop from "./common/scrollToTop";

//Static Pages Imports
import HomePage from "./components/pages";
import About from "./components/pages/about";
import Privacy from "./components/pages/privacy.jsx";
import TermsAndCondition from "./components/pages/termsAndCondition.jsx";
import Attorneys from "./components/pages/attorneys";
import Faq from "./components/pages/faq";
import PageNotFound from "./components/pages/pagenotfound";
import CongratulationMassage from "./components/pages/congratulationMassage";

//User Imports
import Login from "./components/dashBoardPages/login";
import CreditCounseling from "./components/pages/creditCounseling";
import DashboardPage from "./components/pages/dashboardPage";
import DashboardSuccess from "./components/pages/dashboardPage-Success";
import JointFilerInfo from "./components/pages/jointFiler-info";
import PaymentPage from "./components/pages/paymentPage";
import RegistrationPage from "./components/pages/registrationPage";
import UserProfileUpdate from "./components/pages/userProfileUpdate";
import UserProfile from "./components/pages/userProfile";
import EnrollmentInFirstCourse from "./components/pages/enrollment-in-First-course";
import SetUpAttorneyAccountUser from "./components/pages/Set-up-Attorney-Account";
import Course from "./components/pages/course";
import HowDidThisHappen from "./components/pages/how-did-this-happen";
import CourseReviewQuestion from "./components/pages/course-review-question";
import NetWorthStepper from "./components/pages/net-worth-stepper";
import ResetPassword from "./components/pages/reset-password";
import ForgotPassword from "./components/pages/forgotPassword";
import SpendingPlan from "./components/pages/spending-plan";
import ContactUs from "./components/pages/contactUs";
import EnrollmentIn2ndCourse from "./components/pages/enrollment-in-2nd-course";
import ReportAnIssue from "./components/pages/reportAnIssue";
import Course2 from "./components/pages/course2";
import SecurityQuestion from "./components/pages/security-question";
import BudgetAnalysis from "./components/pages/budget-analysis";
import BudgetAnalysisIncome from "./components/pages/budget-analysis-income";
import BudgetAnalysisExpenses from "./components/pages/budget-analysis-expenses";
import BudgetAnalysisDebts from "./components/pages/budget-analysis-debts";
import LocateCertificates from "./components/pages/locate-certificates";
import RequestClientCards from "./components/pages/request-client-cards";
import AddInfoMultistep from "./components/dashBoardPages/add-info-multistep";
import CertificateToCourt from "./components/dashBoardPages/certificate-to-court.jsx";
import ContactWithCounselor from "./components/pages/contactwithcounselor.jsx";
import LanguageRequestForm from "./components/pages/language-request-form.jsx";

// Dashboard Imports
import Dashboard from "./components/dashBoardPages/dashboard";
import ManageStudents from "./components/dashBoardPages/manage-students";
import ManageAttorney from "./components/dashBoardPages/manage-attorney.jsx";
import AttorneyClients from "./components/dashBoardPages/attorney-clients.jsx";
import EditAttorney from "./components/dashBoardPages/edit-attorney.jsx";
import EditingClient from "./components/dashBoardPages/editing-client";
import CoursePrograme from "./components/dashBoardPages/course-programe";
import AddInfoMultistepAdmin from "./components/dashBoardPages/add-info-multistep";
import BudgetAnalysisAdmin from "./components/dashBoardPages/budget-analysis";
import BudgetAnalysisIncomeAdmin from "./components/dashBoardPages/budget-analysis-income";
import BudgetAnalysisExpensesAdmin from "./components/dashBoardPages/budget-analysis-expenses";
import BudgetAnalysisDebtsAdmin from "./components/dashBoardPages/budget-analysis-debts";
import EditMyAccount from "./components/dashBoardPages/edit-my-account";
import SetUpUserAccount from "./components/dashBoardPages/set-up-user-account";
import SetUpAttorneyAccountAdmin from "./components/dashBoardPages/set-up-attorney-account";
import LanguageRequests from "./components/dashBoardPages/language-requests";
import AttorneyEmailAddress from "./components/dashBoardPages/attorney-email-address";
import ManageCouponCodes from "./components/dashBoardPages/manage-coupon-codes";
import PendingBilling1stcourse from "./components/dashBoardPages/Pending-Billing(1s-course)";
import PendingBilling2ndcourse from "./components/dashBoardPages/Pending-Billing(2s-course)";
import EvaluationStats1stCourse from "./components/dashBoardPages/Evaluation-Stats(1st-course)";
import EvaluationStats2ndCourse from "./components/dashBoardPages/Evaluation-Stats(2st-course)";
import EnrollmentStats1stCourse from "./components/dashBoardPages/Enrollment-Stats(1st-Course)";
import TrainingAndHowTo from "./components/dashBoardPages/training-and-how-to";
import DashboardCourse from "./components/dashBoardPages/dashboardcourse";
import EvergreenCourse from "./components/pages/evergreen-course";
import ClientCardsOrders from "./components/dashBoardPages/client-cards-orders";
import Certificates from "./components/dashBoardPages/certificates";
import Followup from "./components/dashBoardPages/followup";
import Booklets from "./components/dashBoardPages/booklets";

// Attorney Imports
import ManageClients from "./components/attorneyDashboardPages/manage-clients";
import EditAttorneyAccount from "./components/attorneyDashboardPages/edit-attorney-account";
import AttoneyCardRequest from "./components/attorneyDashboardPages/attoney-card-request";
import AttorneyIssueReport from "./components/attorneyDashboardPages/attorney-issue-report";
import MakeSuggestion from "./components/attorneyDashboardPages/make-suggestion";
import LeaveTestimonial from "./components/attorneyDashboardPages/leave-testimonial";
import PrintableInfoSheets from "./components/attorneyDashboardPages/printable-info-sheets";

import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/bootstrap.min.js";
import Course3 from "./components/pages/course3.jsx";
import CompleteReport from "./components/dashBoardPages/CompleteReport.jsx";

function App() {
  const navigate = useNavigate();
  const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("isLoggedIn");
      navigate("/login");
    }
  }, []);

  return (
    ((localStorage.getItem("token") &&
      !isTokenExpired(localStorage.getItem("token"))) ||
      !localStorage.getItem("token")) && (
      <div className="App">
        <ScrollToTop />
        <Routes>
          {/* Static Routes */}
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/attorneys" element={<Attorneys />} />
          <Route
            path="/congratulationmassage"
            element={<CongratulationMassage />}
          />
          <Route
            path="/contactwithcounselor"
            element={<ContactWithCounselor />}
          />
          <Route path="*" element={<PageNotFound />} />

          {/* Auth/Misc. Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/contactus" element={<ContactUs />} />

          <Route
            path="/setup-attorney-account"
            element={<SetUpAttorneyAccountUser />}
          />
          <Route path="/locate-certificates" element={<LocateCertificates />} />
          <Route
            path="/request-client-cards"
            element={<RequestClientCards />}
          />
          <Route path="/report-an-issue" element={<ReportAnIssue />} />

          {/* User Routes */}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes role="User" component={<DashboardPage />} />
            }
          />
          <Route
            path="/userprofile"
            element={
              <ProtectedRoutes role="User" component={<UserProfile />} />
            }
          />
          <Route
            path="/enrollmentinfirstcourse"
            element={
              <ProtectedRoutes
                role="User"
                component={<EnrollmentInFirstCourse />}
              />
            }
          />
          <Route
            path="/enrollmentin2ndcourse"
            element={
              <ProtectedRoutes
                role="User"
                component={<EnrollmentIn2ndCourse />}
              />
            }
          />
          <Route
            path="/security-law-questions"
            element={
              <ProtectedRoutes role="User" component={<SecurityQuestion />} />
            }
          />
          <Route
            path="/course"
            element={<ProtectedRoutes role="User" component={<Course />} />}
          />
          <Route
            path="/2nd-course"
            element={<ProtectedRoutes role="User" component={<Course2 />} />}
          />
          <Route path="/720course" element={<Course3 />} />
          <Route
            path="/add-info-multistep"
            element={
              <ProtectedRoutes role="User" component={<AddInfoMultistep />} />
            }
          />

          <Route
            path="/budget-analysis"
            element={
              <ProtectedRoutes role="User" component={<BudgetAnalysis />} />
            }
          />
          <Route
            path="/budget-analysis-income"
            element={
              <ProtectedRoutes
                role="User"
                component={<BudgetAnalysisIncome />}
              />
            }
          />
          <Route
            path="/budget-analysis-debts"
            element={
              <ProtectedRoutes
                role="User"
                component={<BudgetAnalysisDebts />}
              />
            }
          />
          <Route
            path="/budget-analysis-expenses"
            element={
              <ProtectedRoutes
                role="User"
                component={<BudgetAnalysisExpenses />}
              />
            }
          />
          <Route
            path="/how-did-this-happen"
            element={
              <ProtectedRoutes role="User" component={<HowDidThisHappen />} />
            }
          />
          <Route
            path="/net-worth"
            element={
              <ProtectedRoutes role="User" component={<NetWorthStepper />} />
            }
          />
          <Route
            path="/spending-plan"
            element={
              <ProtectedRoutes role="User" component={<SpendingPlan />} />
            }
          />
          <Route
            path="/review-questions"
            element={
              <ProtectedRoutes
                role="User"
                component={<CourseReviewQuestion />}
              />
            }
          />
          <Route
            path="/creditcounseling"
            element={
              <ProtectedRoutes role="User" component={<CreditCounseling />} />
            }
          />
          <Route
            path="/dashboardsuccess"
            element={
              <ProtectedRoutes role="User" component={<DashboardSuccess />} />
            }
          />
          <Route
            path="/jointfilerinfo"
            element={
              <ProtectedRoutes role="User" component={<JointFilerInfo />} />
            }
          />
          <Route
            path="/paymentpage"
            element={
              <ProtectedRoutes role="User" component={<PaymentPage />} />
            }
          />
          <Route
            path="/userprofileupdate"
            element={
              <ProtectedRoutes role="User" component={<UserProfileUpdate />} />
            }
          />
          <Route
            path="/addinfomultistep"
            element={
              <ProtectedRoutes role="User" component={<AddInfoMultistep />} />
            }
          />

          <Route
            path="/request-language"
            element={
              <ProtectedRoutes
                role="User"
                component={<LanguageRequestForm />}
              />
            }
          />

          {/* Attorney Routes */}
          <Route
            path="/manage-clients"
            element={
              <ProtectedRoutes role="Atty" component={<ManageClients />} />
            }
          />
          <Route
            path="/edit-attorney-account"
            element={
              <ProtectedRoutes
                role="Atty"
                component={<EditAttorneyAccount />}
              />
            }
          />
          <Route
            path="/attoney-card-request"
            element={
              <ProtectedRoutes role="Atty" component={<AttoneyCardRequest />} />
            }
          />
          <Route
            path="/attorney-issue-report"
            element={
              <ProtectedRoutes
                role="Atty"
                component={<AttorneyIssueReport />}
              />
            }
          />
          <Route
            path="/make-suggestion"
            element={
              <ProtectedRoutes role="Atty" component={<MakeSuggestion />} />
            }
          />
          <Route
            path="/leave-testimonial"
            element={
              <ProtectedRoutes role="Atty" component={<LeaveTestimonial />} />
            }
          />
          <Route
            path="/printable-info-sheets"
            element={
              <ProtectedRoutes
                role="Atty"
                component={<PrintableInfoSheets />}
              />
            }
          />

          {/* Dashboard Routes */}
          <Route
            path="/admin-dashboard"
            element={<ProtectedRoutes role="Admin" component={<Dashboard />} />}
          />
          <Route
            path="/managestudents"
            element={
              <ProtectedRoutes role="Admin" component={<ManageStudents />} />
            }
          />
          <Route
            path="/manageattorney"
            element={
              <ProtectedRoutes role="Admin" component={<ManageAttorney />} />
            }
          />
          <Route
            path="/attorney-clients"
            element={
              <ProtectedRoutes role="Admin" component={<AttorneyClients />} />
            }
          />
          <Route
            path="/edit-attorney"
            element={
              <ProtectedRoutes role="Admin" component={<EditAttorney />} />
            }
          />
          <Route
            path="/editstudent"
            element={
              <ProtectedRoutes role="Admin" component={<EditingClient />} />
            }
          />
          <Route
            path="/certificate-to-court"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<CertificateToCourt />}
              />
            }
          />
          <Route
            path="/courseprogress"
            element={
              <ProtectedRoutes role="Admin" component={<CoursePrograme />} />
            }
          />
          <Route
            path="/enroll-user-first-course"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<EnrollmentInFirstCourse />}
              />
            }
          />
          <Route
            path="/enroll-user-second-course"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<EnrollmentIn2ndCourse />}
              />
            }
          />
          <Route
            path="/spending-plan-user"
            element={
              <ProtectedRoutes role="Admin" component={<SpendingPlan />} />
            }
          />
          <Route
            path="/user-dashboard-page"
            element={
              <ProtectedRoutes role="Admin" component={<DashboardPage />} />
            }
          />
          <Route
            path="/user-first-course"
            element={<ProtectedRoutes role="Admin" component={<Course />} />}
          />
          <Route
            path="/user-second-course"
            element={<ProtectedRoutes role="Admin" component={<Course2 />} />}
          />
          <Route
            path="/addinfomultistepuser"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<AddInfoMultistepAdmin />}
              />
            }
          />
          <Route
            path="/how-did-this-happen-user"
            element={
              <ProtectedRoutes role="Admin" component={<HowDidThisHappen />} />
            }
          />
          <Route
            path="/net-worth-user"
            element={
              <ProtectedRoutes role="Admin" component={<NetWorthStepper />} />
            }
          />
          <Route
            path="/review-questions-user"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<CourseReviewQuestion />}
              />
            }
          />
          <Route
            path="/budget-analysis-report"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<BudgetAnalysisAdmin />}
              />
            }
          />
          <Route
            path="/income-analysis"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<BudgetAnalysisIncomeAdmin />}
              />
            }
          />
          <Route
            path="/expenses-analysis"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<BudgetAnalysisExpensesAdmin />}
              />
            }
          />
          <Route
            path="/debts-analysis"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<BudgetAnalysisDebtsAdmin />}
              />
            }
          />
          <Route
            path="/editmyaccount"
            element={
              <ProtectedRoutes role="Admin" component={<EditMyAccount />} />
            }
          />
          <Route
            path="/setupuseraccount"
            element={
              <ProtectedRoutes role="Admin" component={<SetUpUserAccount />} />
            }
          />
          <Route
            path="/setupattorneyaccount"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<SetUpAttorneyAccountAdmin />}
              />
            }
          />
          <Route
            path="/trainingandhowto"
            element={
              <ProtectedRoutes role="Admin" component={<TrainingAndHowTo />} />
            }
          />
          <Route
            path="/languagerequests"
            element={
              <ProtectedRoutes role="Admin" component={<LanguageRequests />} />
            }
          />
          <Route
            path="/attorneyemailaddress"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<AttorneyEmailAddress />}
              />
            }
          />
          <Route
            path="/managecouponcodes"
            element={
              <ProtectedRoutes role="Admin" component={<ManageCouponCodes />} />
            }
          />
          <Route
            path="/pendingbilling1stcourse"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<PendingBilling1stcourse />}
              />
            }
          />
          <Route
            path="/pendingbilling2ndcourse"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<PendingBilling2ndcourse />}
              />
            }
          />
          <Route
            path="/evaluationstats1stcourse"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<EvaluationStats1stCourse />}
              />
            }
          />
          <Route
            path="/completionreport"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<CompleteReport />}
              />
            }
          />
          <Route
            path="/evaluationstats2ndcourse"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<EvaluationStats2ndCourse />}
              />
            }
          />
          <Route
            path="/enrollmentstats1stcourse"
            element={
              <ProtectedRoutes
                role="Admin"
                component={<EnrollmentStats1stCourse />}
              />
            }
          />
          <Route
            path="/dashboardcourse"
            element={
              <ProtectedRoutes role="Admin" component={<DashboardCourse />} />
            }
          />
          <Route
            path="/evergreencourse"
            element={
              <ProtectedRoutes role="Admin" component={<EvergreenCourse />} />
            }
          />
          <Route
            path="/booklets"
            element={<ProtectedRoutes role="Admin" component={<Booklets />} />}
          />
          <Route
            path="/clientcardsorders"
            element={
              <ProtectedRoutes role="Admin" component={<ClientCardsOrders />} />
            }
          />
          <Route
            path="/certificates"
            element={
              <ProtectedRoutes role="Admin" component={<Certificates />} />
            }
          />
          <Route
            path="/followup"
            element={<ProtectedRoutes role="Admin" component={<Followup />} />}
          />
        </Routes>
      </div>
    )
  );
}

export default App;
