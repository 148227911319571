import React, { useEffect, useState } from "react";

import logo from "../../assets/images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../api/server";
import { login } from "../../store/userSlice";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import updateInstance from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const Login = () => {

  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });
  const [postLoading, setPostLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);
  
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      dispatch(login(storedUser));
    }
  }, [dispatch]);

  const validationSchema = Yup.object({
    username: Yup.string().required("User Name is required"),

    password: Yup.string().required("Password is required"),
  });
  const {
    handleChange,
    touched,
    values,
    resetForm,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: loginCredentials,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setPostLoading(true);
      userLogin(values)
        .then((res) => {
          setPostLoading(false);
          if (res.status === 200) {
            // const axiosInstance = updateInstance();
            updateInstance(res.data.token)
            // axiosInstance.defaults.headers[
            //   "Authorization"
            // ] = `Bearer ${res.data.token}`;
            dispatch(login(res.data));
            localStorage.setItem("isJoint", res.data.isJoint);
            // updateInstance(res.data.token)
            
            if (res.data.data.type === "Admin") {
              navigate("/admin-dashboard");
            } else if (res.data.data.type === "Atty") {
              navigate("/manage-clients");
            } else {
              navigate("/dashboard");
            }
          }
        })
        .catch((err) => {
          setPostLoading(false);
          if (err.response && err.response.data && err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    },
  });

  useEffect(() => {
    if (token) {
      if (user.user.data.type === "Admin") {
        navigate("/admin-dashboard");
      } else if (user.user.data.type === "Atty") {
        navigate("/manage-clients");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div>
      <section className="login_wrap">
      <div className="login_form ">
          <div className="content_login_form">
            <div className="login_logo">
              <Link to={`/`}>
                <img src={logo} alt="logo" />
              </Link>
              <p className="f18">
                Please enter your username/email or password
              </p>
            </div>

            {/* login Form */}

            <div className="mt-60">
              <form onSubmit={handleSubmit}>
                <div className=" input_wrap ">
                  <label className="label-title">Username or Email</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="username"
                    value={values.username}
                    type="text"
                    className="form-control"
                    placeholder="Enter your username or email"
                  />
                  {errors.username && touched.username ? (
                    <p className="text-danger">{errors.username}</p>
                  ) : null}
                </div>
                <div className=" input_wrap mt-40">
                  <label className="label-title">Password</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    value={values.password}
                    type={showPass ? "text" : "password"}
                    className="form-control pe-5"
                    placeholder="Enter password"
                  />
                  {showPass ? (
                    <i
                      className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowPass(false);
                      }}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowPass(true);
                      }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {errors.password && touched.password ? (
                    <p className="text-danger">{errors.password}</p>
                  ) : null}
                </div>
                <Link
                  to="/forgot-password"
                  className="text_success mt-4  d-flex"
                >
                  <b>Forgot Password?</b>
                </Link>
                <button
                  className="btn_brnd mt-4 me-3  login_btn"
                  type="submit"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="60"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Login"
                  )}
                </button>
              </form>
              {/* <p className="mt-4 f16">
                Don't have an account:{" "}
                <Link to={`/registration`} className="text_success  ">
                  <b>Sign up now</b>
                </Link>
              </p> */}
            </div>
          </div>
        </div>
        <div className="info_login_content">
          <div className="content_login d-flex flex-column justify-content-center align-items-center" style={{color:'white'}}>
          <h1 style={{fontSize:'48px'}}>
          Not a member yet?
       
                </h1>
                <h2 className="f18" style={{color:'white'}}>
                Join us today by signing up!
                </h2>
                <Link to={`/registration`} className="">
                  <h5 className="signup-link  mt-4 me-3 " style={{fontSize:"18px"}}>Sign up</h5> 
                </Link>
          </div>
        </div>
       
      </section>
    </div>
  );
};

export default Login;
