import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import navbarCloseIcon from "../assets/images/navbar-close-icon.svg";
import dashboard from "../assets/images/dashboard.svg";
import managestudents from "../assets/images/managestudents.svg";
import manageattorney from "../assets/images/manageattorney.svg";
import managecourse from "../assets/images/managecourse.svg";
import Report from "../assets/images/ReportImage.svg";
import clientcards from "../assets/images/clientcards.svg";
import training from "../assets/images/training.svg";
import language from "../assets/images/language.svg";
import evaluation from "../assets/images/evaluation.svg";
import certificates from "../assets/images/certificates.svg";
import pending from "../assets/images/pending.svg";

const DashBoardSidebar = () => {

  const tabs = [
    {
      label: "Dashboard",
      link: "/admin-dashboard",
      img: dashboard,
    },
    {
      label: "Manage Students",
      link: "/managestudents",
      img: managestudents,
    },
    {
      label: "Manage Attorneys",
      link: "/manageattorney",
      img: manageattorney,
    },
    {
      label: "Manage Courses",
      img: managecourse,
    },
    {
      label: "Reports",
      img: Report,
    },
    {
      label: "Certificates",
      link: "/certificates",
      img: certificates,
    },
    {
      label: "Client Card Orders",
      link: "/clientcardsorders",
      img: clientcards,
    },
    {
      label: "Training and How-to",
      link: "/trainingandhowto",
      img: training,
    },
    {
      label: "Language requests",
      link: "/languagerequests",
      img: language,
    },

    // {
    //   label: "Attorney Email Addresses",
    //   link: "/attorneyemailaddress",
    //   img: img3,
    // },
    {
      label: "Pending Billing (CC)",
      link: "/pendingbilling1stcourse",
      img: pending,
    },
    {
      label: "Pending Billing (DE)",
      link: "/pendingbilling2ndcourse",
      img: pending,
    },

    // {
    //   label: "Enrollment Stats (1st Course)",
    //   link: "/enrollmentstats1stcourse",
    //   img: img2,
    // },
    // {
    //   label: "Enrollment Stats (2nd Course)",
    //   link: "/enrollmentstats2ndcourse",
    //   img: img1,
    // },
    // {
    //   label: "Budget Analysis",
    //   link: "/404",
    //   img: img1,
    // },
    // {
    //   label: "Budget Analysis PDF",
    //   link: "/404",
    //   img: img3,
    // },
    // {
    //   label: "Certificates",
    //   link: "/certificates",
    //   img: img3,
    // },
    // {
    //   label: "Booklets and Certs to Mail",
    //   link: "/booklets",
    //   img: img2,
    // },
  ];

  return (
    <div>
      <nav id="sidebar">
        <div class="sidebar-header">
          <div class="logo_Wrap">
            <Link className="logo_hover" to={"/"}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div class="navbar_header mb_show_cross">
            <button
              type="button"
              id="sidebarCollapse_close"
              class=" close_btn"
              onClick={() => {
                document.getElementById("sidebar").style.marginLeft = "-325px";
              }}
            >
              <img
                src={navbarCloseIcon}
                alt="navbarCloseIcon"
                class="close_btn"
              />
            </button>
          </div>
        </div>
        <ul class="list-unstyled components adminSidebar">
          {tabs.map((tab) =>
            tab.label === "Manage Courses" ? (
              <div class="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle manageCourse"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    style={{ marginRight: "12px" }}
                    src={tab.img}
                    alt="img1"
                  />
                  {tab.label}
                </button>
                <ul
                  className="dropdown-menu admindrop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <NavLink
                      to={"/evaluationstats1stcourse"}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      <img src={evaluation} alt="img1" />
                      Evaluation Stats (CC)
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/evaluationstats2ndcourse"}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      <img src={evaluation} alt="img1" />
                      Evaluation Stats (DE)
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : 
            tab.label === "Reports" ? (
              <div class="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle manageCourse"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    style={{ marginRight: "12px" }}
                    src={tab.img}
                    alt="img1"
                  />
                  {tab.label}
                </button>
                <ul
                  className="dropdown-menu admindrop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <NavLink
                      to={"/completionreport"}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      <img src={evaluation} alt="img1" />
                      Completion Report
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to={"/evaluationstats2ndcourse"}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      <img src={evaluation} alt="img1" />
                      Evaluation Stats (DE)
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            ) :
            (
              <li>
                <NavLink to={tab.link} activeClassName="active">
                  <img src={tab.img} alt="img1" /> {tab.label}
                </NavLink>
              </li>
            )
          )}
        </ul>
        
      </nav>
    </div>
  );
};

export default DashBoardSidebar;
