import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllClients, searchClients } from "../../api/server";
import AttorneyDashBoardSidebar from "../../layouts/attorneyDashBoardSidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import PaginatedItems from "../dashBoardPages/pagination";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const validationSchema = Yup.object({
  search: Yup.string().required("Name/Email is required"),
});

const ManageClients = () => {
  const attorney = useSelector((state) => state.user.user.data.firstname);
  const code = useSelector((state) => state.user.user.data.attorneyCode);
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();

  const checked = ["checked", "Checked", "complete", "Complete"];

  const { handleChange, touched, values, errors, handleSubmit, resetForm } =
    useFormik({
      initialValues: { search: "", attorneyname: attorney },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (searchCache[pageNo]) {
          // Data already in cache, no need for API call
          const { cachedResponse, cachedTotal } = searchCache[pageNo];
          setResponse(cachedResponse);
          setTotal(cachedTotal);
        } else {
          setFilterLoading(true);
          setGetLoading(true);
          searchClients(pageNo, values)
            .then((res) => {
              setActiveSearch(true);
              const newData = res.data.result;
              const newTotal = res.data.total;
              setSearchCache((prevCache) => ({
                ...prevCache,
                [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
              }));
              setResponse(newData);
              setTotal(newTotal);
              if (pageNo === 1) toast.success("Client search successful");
              setFilterLoading(false);
              setGetLoading(false);
            })
            .catch((err) => {
              toast.error(err.response.data.error);
              setFilterLoading(false);
              setGetLoading(false);
            });
        }
      },
    });

  const handlePageChange = () => {
    if (activeSearch) handleSubmit(values.search);
  };

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[pageCache.pageNo];
    setPageNo(pageCache.pageNo);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setResponse(cachedResponse);
    resetForm();
    toast.success("Client search cleared");
  };

  const handleCopy = async (url) => {
    await navigator.clipboard.writeText(url);
    toast.success("URL copied");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        try {
          const res = await getAllClients(pageNo, { attorneyname: attorney });
          const ungroupData = res.data.result;

          const groupedById = {};

          ungroupData.forEach((obj) => {
            if (!groupedById[obj.userID]) {
              groupedById[obj.userID] = [];
            }
            groupedById[obj.userID].push(obj);
          });

          const newData = Object.values(groupedById);
          const newTotal = res.data.total;

          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          // Set state with new data
          setResponse(newData);
          setTotal(newTotal);
        } catch (err) {
          toast.error(err.response.data.error);
        } finally {
          setGetLoading(false);
        }
      }
    };
    if (!activeSearch) {
      fetchData();
    }
  }, [pageNo, pageCache]);

  return (
    <div>
      <div className="wrapper">
        <AttorneyDashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          {/* <!-- Page Content Holder --> */}
          <section className="manage-students">
            {code && (
              <p>
                Association Link:{" "}
                <b>https://evergreenclass.com/registration?code={code}</b>
                <button
                  class="bg-white border-0 ms-5"
                  onClick={() => {
                    handleCopy(
                      "https://evergreenclass.com/registration?code=" + code
                    );
                  }}
                >
                  <i class="fa fa-clone"></i>
                </button>
              </p>
            )}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h4 className="card-title">Manage Clients</h4>
                  </div>
                  <div className="col-lg-4">
                    <form onSubmit={handleSubmit}>
                      <div className="table-search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          style={{ paddingRight: "35px" }}
                          onChange={handleChange}
                          name="search"
                          value={values.search}
                        />
                        <button
                          disabled={filterLoading || getloading}
                          type="submit"
                          onClick={() => {
                            setPageCache((prevCache) => ({
                              ...prevCache,
                              pageNo: pageNo,
                            }));
                            setPageNo(1);
                            setSearchCache({});
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                      {errors.search && touched.search ? (
                        <p className="text-danger">{errors.search}</p>
                      ) : null}
                      {activeSearch && (
                        <button
                          className="btn_brnd mt-3 me-3 flex"
                          onClick={handleClearFilters}
                        >
                          Clear Search
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-body manage-client-responsive">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Course 1</th>
                      <th scope="col">Course 2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((users, index) =>
                        users.map((user, ind) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.type === "Joint"
                                ? user.jointFirstName === "" &&
                                  user.jointLastName === ""
                                  ? user.userFirstName +
                                    " " +
                                    user.userLastName +
                                    "'s Joint"
                                  : user.jointFirstName +
                                    " " +
                                    user.jointLastName +
                                    " (Joint)"
                                : user.userFirstName + " " + user.userLastName}
                            </th>
                            <td
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.userEmail}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {" "}
                              {user.courseFirstId &&
                              user.courseFirstGenterated == "yes" ? (
                                user.courseFirstDownloaded &&
                                user.courseFirstDownloaded !== "yes" ? (
                                  <a
                                    className="text-black"
                                    href={user.courseFirstDownloaded}
                                    target="_blank"
                                  >
                                    {user.courseFirstDate
                                      ? user.courseFirstDate.split("T")[0]
                                      : ""}
                                  </a>
                                ) : user.courseFirstDate ? (
                                  <span className="tooltips">
                                    {user.courseFirstDate.split("T")[0]}
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : user.courseFirstGenterated == "" &&
                                user.perFirstCourse === 100 ? (
                                "Pending"
                              ) : checked.includes(user.activated_first) ? (
                                user.perFirstCourse.toFixed(0) + "%"
                              ) : (
                                "Not Enrolled"
                              )}
                            </td>

                            <td
                              style={{
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.courseSecondId &&
                              user.generated == "yes" ? (
                                user.downloaded && user.downloaded !== "yes" ? (
                                  <a
                                    className="text-black"
                                    href={user.downloaded}
                                    target="_blank"
                                  >
                                    {user.date ? user.date.split("T")[0] : ""}
                                  </a>
                                ) : user.date ? (
                                  <span className="tooltips">
                                    {user.date.split("T")[0]}
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : user.generated == "" &&
                                user.perSecondCourse === 100 ? (
                                "Pending"
                              ) : checked.includes(user.activated_second) ? (
                                user.perSecondCourse.toFixed(0) + "%"
                              ) : (
                                "Not Enrolled"
                              )}
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>No client found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!filterLoading && !getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageClients;
