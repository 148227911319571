import React, { useState } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { locateCertificate } from "../../api/server";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  ssn: Yup.string().required("Last Four Digits of SSN is required"),
});

const LocateCertificates = () => {
  const [response, setResponse] = useState([]);
  const [searchLoading, setSearchLoading] = useState();

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="my-profile">
          <h1 id="firstcourse">
            Locate<span className="text_success"> Certificates</span>
          </h1>
          <div className="col-lg-3 col-md-6 certificate">
            <Formik
              initialValues={{ firstname: "", lastname: "", ssn: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                setSearchLoading(true);
                locateCertificate(values)
                  .then((res) => {
                    setResponse(res.data.data);
                    if (res.data.data.length === 0) {
                      toast.error("No certificate found");
                    } else {
                      resetForm();
                    }
                    setSearchLoading(false);
                  })
                  .catch((err) => {
                    toast.error(err.response.data.error);
                    setSearchLoading(false);
                  });
              }}
            >
              <Form>
                <div className="row mt-60 align-items-center">
                  <div className="col-lg-3">
                    <div className="input_wrap">
                      <label className="label-title position-static fa-14">
                        First Name
                      </label>
                      <Field
                        type="text"
                        name="firstname"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="error text-danger"
                        style={{ fontWeight: "400" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="input_wrap">
                      <label className="label-title position-static fa-14">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        name="lastname"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="error text-danger"
                        style={{ fontWeight: "400" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="input_wrap">
                      <label className="label-title position-static fa-14">
                        Last 4 digits of SSN
                      </label>
                      <Field
                        type="number"
                        name="ssn"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="ssn"
                        component="div"
                        className="error text-danger"
                        style={{ fontWeight: "400" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="btn_wrap text-center">
                      <button
                        type="submit"
                        className="btn_brnd"
                        style={{ marginBottom: "40px", marginTop: "25px" }}
                        disabled={searchLoading}
                      >
                        {searchLoading ? (
                          <ThreeDots
                            height="20"
                            width="60"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "block",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>

        {!searchLoading ? (
          response.length == 0 ? (
            <div class="box_text mt-60 text-center p-height">
              <p>
                No results found. If you feel that the course has been completed
                and the information is not <br /> showing up, please give us a
                call at 503-506-8813 and we will help you right away.
              </p>
            </div>
          ) : (
            <div style={{overflow:'auto'}}>
              <div
                className="head mt-60 certificate"
                style={{ width: "1108px" }}
              >
                <h1 className="f18">Client Name</h1>
                <h1 className="f18">1st Course</h1>
                <h1 className="f18">2nd Course</h1>
                <h1></h1>
              </div>
              {response.map((item, index) => (
                <div
                  className="certificate content_certificate"
                  style={{ width: "1108px" }}
                >
                  <p>
                    {
                      (item.type == "Main"
                        ? item.userFirstName + " " + item.userLastName
                        : item.jointFirstName + " " + item.jointLastName)
                    }
                  </p>
                  <p>
                    {item.courseFirstId ? (
                      item.courseFirstGenerated === "" ? (
                        "Pending"
                      ) : item.courseFirstDownloaded &&
                        item.courseFirstDownloaded !== "yes" ? (
                        <a
                          href={item.courseFirstDownloaded}
                          target="_blank"
                          className="text-black"
                        >
                          {item.courseFirstDate.split("T")[0]}
                        </a>
                      ) : (
                        <span className="tooltips">
                          {item.courseFirstDate.split("T")[0]}
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    {item.courseSecondId ? (
                      item.generated === "" ? (
                        "Pending"
                      ) : item.downloaded && item.downloaded !== "yes" ? (
                        <a
                          href={item.downloaded}
                          target="_blank"
                          className="text-black"
                        >
                          {item.date.split("T")[0]}
                        </a>
                      ) : (
                        <span className="tooltips">
                          {item.date.split("T")[0]}
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </p>
                  <p></p>
                </div>
              ))}
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default LocateCertificates;
